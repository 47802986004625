export const demoInfo = [  {     title: "Demo Cupon",     link: "https://demo-ar-cupon.inmersys.xyz/",     images: [       {name: "inmerblack", src: require("../assets/marcadores/img/inmerblack.png") }     ] 
  },
  { 
    title: "Demo Image", 
    link: "https://demo-ar-img.inmersys.xyz/", 
    images: [ 
      {name: "dental", src: require("../assets/marcadores/cupon/DentalPerfectlogo.png")}, 
      {name: "dove", src: require("../assets/marcadores/cupon/Dovebabylogo1.png")},
      {name: "jumex", src: require("../assets/marcadores/cupon/Jumex355ml.png")} 
    ] 
  },
];