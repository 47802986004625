import React, { useState } from "react";
import { ButtonCopy } from "../components/buttons/ButtonCopy";
import { ButtonLink } from "../components/buttons/ButtonLink";
import { InputText } from "../components/Inputs/InputText";
import { projectsInfo } from "./projectsInfo";
import { demoInfo } from "./demoInfo";
import { ButtonImg } from "../components/buttons/ButtonImg";
// ejemplo de uso de store con toolkit

const filteredProject = (data, searchProject) => {
	return data.filter((project) => project.title.toLowerCase().includes(searchProject.toLowerCase()));
};

export const Home = () => {
	const [searchProject, setSearchProject] = useState("");
	const [searchDemo, setSearchDemo] = useState("");

	return (
		<div className="pt-4 w-full min-h-screen bg-[#1A1762] ">
			<div className="w-full flex justify-center">
				<div className="w-[800px] h-auto flex flex-wrap">
					<div className="w-full h-auto flex justify-start py-4">
						<InputText
							name="searchProject"
							placeholder="Buscar Proyecto"
							onChange={(e) => setSearchProject(e.target.value)}
							value={searchProject}
							className="border rounded py-2 px-3 focus:outline-none"
							id="username"
						/>
					</div>
					{filteredProject(projectsInfo, searchProject).map((project) => {
						const { link, title, ios, google, vr } = project;
						return (
							<div className="bg-[#201d7b] px-2 w-full md:w-1/2 h-20 flex items-center" key={link}>
								<ButtonCopy link={link} />
								<ButtonLink title={title} link={link} ios={ios} google={google} vr={vr} />
							</div>
						);
					})}
				</div>
			</div>
			<div className="w-full flex justify-center">
				<div className="w-[800px] h-auto flex flex-wrap">
					<h1 className="text-white text-3xl font-bold my-5">Demos</h1>
					<div className="w-full h-auto flex justify-start py-4">
						<InputText
							name="searchDemo"
							placeholder="Buscar Demos"
							onChange={(e) => setSearchDemo(e.target.value)}
							value={searchDemo}
							className="border rounded py-2 px-3 focus:outline-none"
							id="username"
						/>
					</div>
					{filteredProject(demoInfo, searchDemo).map((project) => {
						console.log(project);
						const { link, title, ios, google, vr } = project;
						return (
							<div className="bg-[#201d7b] px-2 w-full md:w-1/2 h-20 flex items-center" key={link}>
								<ButtonCopy link={link} />
								<ButtonLink title={title} link={link} ios={ios} google={google} vr={vr} />
								<ButtonImg images={project.images} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
