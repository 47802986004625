import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";

export const LayoutHome = () => {
    const test = useLoaderData();
    console.log(test);
    return (
        <div>
            <div className="flex bg-[#1A1762] pl-4 pt-4 items-center">
                <img className="w-16" src="https://inmersys.com/proyects/tasvaluo/inmersys.jpg" alt="" />
                <p className="text-white text-3xl font-bold pl-4 " >Inmersys (Apps y proyectos)</p>
            </div>
            <Outlet />
        </div>
    );
};
