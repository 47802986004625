import React from "react";
import openlinkimg from "../../assets/augmentedreality.svg";
function handleButtonClick(images) {
	const newTab = window.open();
	images.forEach((image) => {
		const img = newTab.document.createElement("img");
		const absoluteUrl = new URL(image.src, window.location.origin).href;
		img.src = absoluteUrl;

		img.style.display = "block";
		img.style.maxWidth = "100%";
		img.style.height = "auto";
		img.style.margin = "60px auto";
		newTab.document.body.appendChild(img);
	});
}
export const ButtonImg = ({ images }) => {
	return (
		<button
			type="button"
			className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
			onClick={() => handleButtonClick(images)}
		>
			{"Marcadores"}
			<img src={openlinkimg} className="w-6 mx-1" alt="openlink" />
		</button>
	);
};
