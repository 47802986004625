import React from 'react'
import { useState } from 'react'
import copy from "../../assets/icons8-adjuntar-60.png"
export const ButtonCopy = ({ link }) => {
    const [copytext, setCopy] = useState(false)

    const handleClick = () => {
        setInterval(() => {
            setCopy(false)
        }, 1000);
    }

    return (
        <button
            onClick={() => {
                navigator.clipboard.writeText(link + "")
                setCopy(true)
                handleClick()
            }}
            className='flex flex-col text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
            <img src={copy} className="w-6" alt="algo" />
            {
                copytext && <div className="text-white text-xs" >Copiado!</div>
            }
        </button>
    )
}
