import { createBrowserRouter } from "react-router-dom";
import { LayoutHome } from "../layout/LayoutHome";
import { Home, NotFound } from "../pages/index";

export const PathRoot = {
	root: "/",
	auth: "auth/*",
	notfound: "*",
};

export const AuthRoot = {
	auth: "auth",
	reset: "reset",
};

const routeHome = {
	path: PathRoot.root,
	element: <LayoutHome />,
	children: [
		{
			index: true,
			element: <Home />,
		},
	],
};

// const routerAuth = {
// 	element: <LayoutAuth />,
// 	path: AuthRoot.auth,
// 	children: [
// 		{
// 			index: true,
// 			element: <TemplateForm />,
// 		},
// 		{
// 			path: AuthRoot.reset,
// 			element: <TemplateHeader />,
// 		},
// 	],
// };

export const router = createBrowserRouter([
	{
		path: PathRoot.root,
		// element: <LayoutHome />,
		errorElement: <NotFound />,
		children: [routeHome],
	},
]);
