export const projectsInfo = [
	{ title: "Total play provador virtual", link: "https://provadorvirtual.inmersys.xyz", ios: true },
	{ title: "Tasvaluo App", link: "https://tasvaluo.inmersys.xyz", ios: true },
	{ title: "Pirelli VR", link: "https://pirellivr.inmersys.xyz", google: true },
	{ title: "Nupec sacos AR", link: "https://nupecsacosar.inmersys.xyz", ios: true, google: true },
	{ title: "Nupec Fabrica AR", link: "https://nupecfabricaar.inmersys.xyz", ios: true, google: true },
	{ title: "Nupec Apps", link: "https://nupecapps.inmersys.xyz", ios: true, google: true },
	{ title: "Ge Current App", link: "https://gecurrentapp.inmersys.xyz", ios: true, google: true },
	{ title: "Colgate trabajo en altura", link: "https://trabajoenaltura.inmersys.xyz", vr: true },
	{ title: "Colgate espacios confinados", link: "https://espaciosconfinados.inmersys.xyz", vr: true },
	{ title: "Castrol game", link: "https://castrolgame.inmersys.xyz", vr: true },
	{ title: "Colageno", link: "https://colageno.inmersys.xyz", ios: true },
	{ title: "Banorte incendios", link: "https://banorte.inmersys.xyz", ios: true },
	{ title: "Asteroides", link: "https://asteroides.inmersys.xyz", ios: true },
	{ title: "Nupec-go", link: "https://nupecgo.inmersys.xyz/", ios: true },
];
